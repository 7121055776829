import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtener() {
    var url = ConfigAPI.baseURL + "roles" + Session.getToken();
    return instance.get(url);
  },
  agregar(data) {
    var params = {
      name: data.name,
      profile_id: data.profile_id,
    }
    var url = ConfigAPI.baseURL + "roles" + Session.getToken();
    return instance.post(url,params);
  },
  editar(data) {
    var params = {
      name: data.name,
      profile_id: data.profile_id,
    }
    var url = ConfigAPI.baseURL + "roles/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminar(id) {
    var url = ConfigAPI.baseURL + "roles/"+ id + Session.getToken();
    return instance.delete(url);
  },
  obtenerPermisos() {
    var url = ConfigAPI.baseURL + "permissions" + Session.getToken();
    return instance.get(url);
  },
}

export default servicesAPI;
