<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="5">
            <h4 id="traffic" class="card-title mb-0">Roles</h4>
            <div class="small text-muted">Configuración de roles de perfiles</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col sm="4" v-if="hasPermissionModule(modules.personal)">
          <b-card header-tag="header" footer-tag="footer">
            <template v-slot:header>
              <b-row>
                <b-col sm="5">
                  <h6 class="mb-0 card-header-roles-custom">Staff</h6>
                </b-col>
                <b-col sm="7">
                  <b-button @click="addPersonal()" type="button" variant="outline-primary" size="sm" class="btn-pill float-right" v-b-tooltip.hover title="Agregar Roles para el perfil <Staff>">
                    <i class="fa fa-plus"></i>
                  </b-button>
                </b-col>
              </b-row>
            </template>

            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tablePersonal.items"
                    :fields="tablePersonal.fields"
                    v-if="tablePersonal.items.length">

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="editPersonal(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="deletePersonal(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>

          </b-card>
        </b-col>

        <b-col sm="4" v-if="hasPermissionModule(modules.cliente)">
          <b-card header-tag="header" footer-tag="footer">
            <template v-slot:header>
              <b-row>
                <b-col sm="5">
                  <h6 class="mb-0 card-header-roles-custom">Cliente</h6>
                </b-col>
                <b-col sm="7">
                  <b-button @click="addCliente()" type="button" variant="outline-primary" size="sm" class="btn-pill float-right" v-b-tooltip.hover title="Agregar Roles para el perfil <Cliente>">
                    <i class="fa fa-plus"></i>
                  </b-button>
                </b-col>
              </b-row>
            </template>

            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tableCliente.items"
                    :fields="tableCliente.fields"
                    v-if="tableCliente.items.length">

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="editCliente(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteCliente(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>

          </b-card>
        </b-col>

        <b-col sm="4" v-if="hasPermissionModule(modules.proveedor)">
          <b-card header-tag="header" footer-tag="footer">
            <template v-slot:header>
              <b-row>
                <b-col sm="5">
                  <h6 class="mb-0 card-header-roles-custom">Proveedor</h6>
                </b-col>
                <b-col sm="7">
                  <b-button @click="addProveedor()" type="button" variant="outline-primary" size="sm" class="btn-pill float-right" v-b-tooltip.hover title="Agregar Roles para el perfil <Proveedor>">
                    <i class="fa fa-plus"></i>
                  </b-button>
                </b-col>
              </b-row>
            </template>

            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tableProveedor.items"
                    :fields="tableProveedor.fields"
                    v-if="tableProveedor.items.length">

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="editProveedor(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteProveedor(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>

          </b-card>
        </b-col>
      </b-row>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
                header-bg-variant="dark"
                header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col>
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="form.name"
                            required
                            placeholder="Ingresar nombre del rol">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'

  export default {
    data: () => {
      return {
        tablePersonal : {
          items: [],
          fields: [
            {key: 'name', label: 'Nombre'},
            {key: 'f_action', label:''},
          ],
        },
        tableCliente : {
          items: [],
          fields: [
            {key: 'name', label: 'Nombre'},
            {key: 'f_action', label:''},
          ],
        },
        tableProveedor : {
          items: [],
          fields: [
            {key: 'name', label: 'Nombre'},
            {key: 'f_action', label:''},
          ],
        },
        form: {
          id: 0,
          name: '',
          profile_id: 0,
        },
        modal: {
          form: {
            active: false,
            title: ''
          }
        },
        arr: {
          permissions: []
        },
        modules: {
          personal: Modules.PERSONAL,
          cliente: Modules.CLIENTES,
          proveedor: Modules.PROVEEDORES
        }
      }
    },
    mounted() {
      this.cargar()
      this.cargarPermissions()
    },
    methods: {
      cargar () {
        var result = serviceAPI.obtener()

        result.then((response) => {
          var data = response.data
          this.tablePersonal.items = []
          this.tableCliente.items = []
          this.tableProveedor.items = []

          data.forEach(element => {
            if( element.profile_id == Profiles.PERSONAL ) {
              this.tablePersonal.items.push(element)
            }

            if( element.profile_id == Profiles.CLIENTE ) {
              this.tableCliente.items.push(element)
            }

            if( element.profile_id == Profiles.PROVEEDOR ) {
              this.tableProveedor.items.push(element)
            }
          });
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });
      },
      cargarPermissions () {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerPermisos()

        result.then((response) => {
          var data = response.data
          this.arr.permissions = data
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },
      hasPermissionModule(module_id) {
        var status = false
        this.arr.permissions.forEach(element =>{
          if(element.id == module_id){
            status = true
          }
        })
        return status
      },
      addPersonal() {
        this.modal.form.title = "Nuevo >> Rol del perfil <Staff>"
        this.addRole(Profiles.PERSONAL)
      },
      editPersonal(item) {
        this.modal.form.title = "Edicción >> Rol del perfil <Staff>"
        this.editRole(item)
      },
      deletePersonal(item) {
        this.form.id = item.id
        this.form.name = item.name
        this.form.profile_id = 0

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.form.id + ') - '+ this.form.name + '?', {
          title: 'Borrar >> Rol del perfil <Staff>',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: "danger",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.remove()
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      addCliente() {
        this.modal.form.title = "Nuevo >> Rol del perfil <Cliente>"
        this.addRole(Profiles.CLIENTE)
      },
      editCliente(item) {
        this.modal.form.title = "Edicción >> Rol del perfil <Cliente>"
        this.editRole(item)
      },
      deleteCliente(item) {
        this.form.id = item.id
        this.form.name = item.name
        this.form.profile_id = 0

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.form.id + ') - '+ this.form.name + '?', {
          title: 'Borrar >> Rol del perfil <Cliente>',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: "danger",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.remove()
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      addProveedor() {
        this.modal.form.title = "Nuevo >> Rol del perfil <Proveedor>"
        this.addRole(Profiles.PROVEEDOR)
      },
      editProveedor(item) {
        this.modal.form.title = "Edicción >> Rol del perfil <Proveedor>"
        this.editRole(item)
      },
      deleteProveedor(item) {
        this.form.id = item.id
        this.form.name = item.name
        this.form.profile_id = 0

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.form.id + ') - '+ this.form.name + '?', {
          title: 'Borrar >> Rol del perfil <Proveedor>',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: "danger",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.remove()
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      addRole(role_id) {
        this.form.id = 0
        this.form.name = ''
        this.form.profile_id = role_id
        this.modal.form.active = true
      },
      editRole(item) {
        this.form.id = item.id
        this.form.name = item.name
        this.form.profile_id = item.profile_id
        this.modal.form.active = true
      },
      save() {
        let loader = this.$loading.show();
        if (this.form.id) {
          var result = serviceAPI.editar(this.form);
        } else {
          var result = serviceAPI.agregar(this.form);
        }

        result.then((response) => {
          loader.hide()
          this.modal.form.active = false
          this.cargar()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      remove() {
        let loader = this.$loading.show();
        if (this.form.id) {
          var result = serviceAPI.eliminar(this.form.id);
        }

        result.then((response) => {
          loader.hide()
          this.cargar()
          this.$awn.success("Registro eliminado");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      }
    }
  }
</script>

<style>
  .card-header-roles-custom {
    font-weight: 500;
    font-size: 15px;
  }
</style>
